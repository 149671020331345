import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Container, Grid, Typography, AppBar, Toolbar, Button } from '@material-ui/core'

import AppCard from './components/AppCard';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: '10vh',
    border: 0
  },
  appBar: {
    top: 'auto',
    bottom: 0,
  },
  grow: {
    flexGrow: 1,
  }
}));

function App() {

  const classes = useStyles();

  const apps = [
    {
      name: "Alanna's Recipe App",
      description: "",
      body: "Yet another recipe app, except this time with a twist for Vegan and Dairy Free recipes!",
      url: "https://recipes.litwicki.app",
      image: ""
    },
    {
      name: "JLIT Custom Woodworking",
      description: "",
      body: "Yet another recipe app, except this time with a twist for Vegan and Dairy Free recipes!",
      url: "https://recipes.litwicki.app",
      image: ""
    },
    {
      name: "The WallPC",
      description: "PCs > Consoles",
      body: "Extreme performance, prestige, and luxury for the PC enthusiast!",
      url: "https://thewallpc.com",
      image: ""
    }
  ];

  return (
    <>
      <Container className={classes.root} >
        <Grid container spacing={5}>
          {apps.map(app => (
            <Grid key={app.name} item xs>
              <AppCard app={app} />
            </Grid>
          ))}
        </Grid>
      </Container>
      <AppBar position="fixed" color="primary" className={classes.appBar}>
        <Toolbar>
          <Typography className={classes.grow} variant="body2"><Button href="https://jakelitwicki.com" variant="text">Copyright &copy; 2020, Jake Litwicki</Button></Typography>
          <Typography variant="body2"><Button variant="contained" href="https://docs.amplify.aws">Powered by AWS Amplify</Button></Typography>
        </Toolbar>
      </AppBar>
    </>
  );
}

export default App;